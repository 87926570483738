import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import Row from 'reactstrap/lib/Row';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { logger } from 'client/utils/isomorphic-logger';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { PageModel } from 'client/data/models/page';
import { VenomVersion } from 'client/data/models/version';
import { TrackingConstant } from 'client/tracking/constant';

import { fireInvalidEmailInput } from 'client/engagement-handlers/profile-engagement-handler/profile-engagement-handler';
import { profileScreenMethods } from 'site-modules/shared/components/profile/profile-screen-methods';
import { insiderMethods } from 'site-modules/shared/components/profile/insider-methods';
import { firebaseValidation } from 'site-modules/shared/components/profile/firebase-validation';
import { formValidation } from 'site-modules/shared/components/form-validation/form-validation';
import { validation } from 'site-modules/shared/components/form-validation/validation';
import { asyncValidation } from 'site-modules/shared/components/form-validation/validation-async';

import { ProfileFormErrors } from 'site-modules/shared/components/profile/profile-form-errors/profile-form-errors';
import { Spinner } from 'site-modules/shared/components/spinner/spinner';
import { FormField } from 'site-modules/shared/components/form-field/form-field';
import { FooterBackToTop } from 'site-modules/shared/components/footer/footer-back-to-top';

import './join-insider-sign-up.scss';

const CREATIVE_ID = 'edm-entry-insider-sign-up-footer';
const SIGN = { true: 'signed in', false: 'all signed up' };
const FIELD_NAME = 'email';
export const validators = {
  email: {
    test: validation.validateEmail,
    asyncTest: (params, options) => asyncValidation.fetchEmailValidation({ ...params, id: 'insider' }, options),
    errorText: 'Please enter your correct email address',
  },
};

function SignUpComponent({
  validationErrors,
  validate,
  fieldsOrder,
  firebaseError,
  isAuthenticated,
  isAnonymousUser,
  className,
  fieldRef,
  toggleSignModal,
  fields,
  signInAnonymousAndSaveProfile,
  validateEmailAsync,
  pageName,
  venomVersion,
  profileScreen,
  isAds10216Chal,
}) {
  const [isSpinnerActive, setSpinnerActive] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [isSucceeded, setSucceeded] = useState(false);
  const [hasAccount, setAccount] = useState(false);
  const isAuthorized = isAuthenticated && !isAnonymousUser;

  useEffect(() => {
    const errorEmail = get(validationErrors, 'email');
    if (errorEmail) {
      fireInvalidEmailInput({ creativeId: CREATIVE_ID, value: 'Sign up with email' });
    }
  }, [validationErrors]);

  async function handleSubmit(event) {
    event.preventDefault();
    setSpinnerActive(true);
    const emailValidation = validate() && (await validateEmailAsync(pageName, venomVersion));

    if (emailValidation && emailValidation.isEmailValid) {
      const emailValue = fields.email.value();
      setSubmitted(true);
      try {
        const dataToPass = { identifiers: { email: [emailValue] }, isAnonymous: true };
        const trackingData = { subaction_name: TrackingConstant.SUCCESS_EMAIL_ONLY_SIGN_UP };
        await signInAnonymousAndSaveProfile(dataToPass, {
          signInAnonymouslyTracking: {
            creativeId: CREATIVE_ID,
            trackingData,
          },
        });
        toggleSignModal({ email: emailValue });
        setAccount(true);
      } catch (e) {
        logger('warn', 'profile is not saved to a storage properly', e);
      }
    } else {
      setSpinnerActive(false);
    }
  }

  useEffect(() => {
    setSpinnerActive(profileScreen !== '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileScreen]);

  useEffect(() => {
    if (isSubmitted && isAuthorized && profileScreen === '') {
      setSucceeded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized, isSubmitted, profileScreen]);

  const isInvisible = !(isAuthenticated === false || isSubmitted); // isAuthenticated !== false => anonymous or registred user
  const isAds10216ChalUIisVisible = isAds10216Chal && !isInvisible;
  const headingColValues = isAds10216ChalUIisVisible
    ? {
        md: null,
        lg: null,
      }
    : {
        md: 6,
        lg: 5,
      };
  const formsColValues = isAds10216ChalUIisVisible
    ? {
        md: 6,
        lg: 6,
      }
    : {
        md: 6,
        lg: 5,
      };
  const xsValue = isInvisible && isAds10216Chal ? 6 : 12;
  const renderH2 = (
    <Fragment>
      <h2 className="heading-3 mb-0">Join Edmunds</h2>
      <p
        className={classNames('mb-0', {
          'size-16': isAds10216ChalUIisVisible,
        })}
      >
        Receive pricing updates, shopping tips & more!
      </p>
    </Fragment>
  );
  return (
    <Fragment>
      {isAds10216Chal && isInvisible && (
        <Fragment>
          <div className="container text-center text-md-end pos-r hidden-sm-down" style={{ top: '60px' }}>
            <FooterBackToTop />
          </div>
          <div className="container text-center text-md-end pos-r hidden-md-up" style={{ top: '100px' }}>
            <FooterBackToTop />
          </div>
        </Fragment>
      )}
      <div
        className={classNames('container-fluid bg-faded', {
          invisible: isInvisible,
        })}
        style={isAds10216ChalUIisVisible ? { paddingTop: '100px' } : null}
      >
        <div className="container">
          <Row
            className={classNames('join-insider-container justify-content-between', className)}
            data-tracking-parent={CREATIVE_ID}
          >
            <Col
              xs={xsValue}
              md={headingColValues.md}
              lg={headingColValues.lg}
              className={classNames({
                'pb-1_5': isAds10216ChalUIisVisible,
                'mb-1_25 mb-md-0': !isAds10216Chal,
              })}
            >
              {isAds10216ChalUIisVisible ? (
                <Fragment>
                  <div className="hidden-md-up pb-3 text-center">
                    <FooterBackToTop />
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>{renderH2}</div>
                    <div className="hidden-sm-down">
                      <FooterBackToTop />
                    </div>
                  </div>
                </Fragment>
              ) : (
                renderH2
              )}
            </Col>
            <Col xs={xsValue} md={formsColValues.md} lg={formsColValues.lg}>
              <Form
                id="join-insider-sign-up-form"
                className="join-insider-sign-up-form"
                noValidate
                onSubmit={handleSubmit}
              >
                <Row
                  className={classNames('justify-content-end', {
                    'is-ads-10216-chal': isAds10216Chal,
                  })}
                >
                  <Col className="pe-0">
                    <FormField
                      className="join-insider-email mb-0"
                      placeholder={isAds10216Chal ? '' : 'email address'}
                      id="join-insider-email"
                      label={isAds10216Chal ? 'Email Address' : ''}
                      labelClassName="ps-0 small mb-0_25"
                      name={FIELD_NAME}
                      type={FIELD_NAME}
                      ref={field => fieldRef(FIELD_NAME, field)}
                      onBlur={validate}
                      isValid={!validationErrors.email}
                      data-tracking-ignore-value
                      data-no-refresh
                      aria-label="Enter Email Address"
                    />
                    {!isAds10216Chal && (
                      <div className="form-group h7 mb-0">
                        <ProfileFormErrors
                          fieldsOrder={fieldsOrder}
                          validationErrors={validationErrors}
                          firebaseError={firebaseError}
                        />
                      </div>
                    )}
                  </Col>
                  <Col
                    xs={3}
                    className={classNames('ps-0', {
                      'd-flex align-items-end': isAds10216Chal,
                    })}
                    style={isAds10216Chal ? { maxWidth: '100px' } : null}
                  >
                    <Button
                      type="submit"
                      color="primary-b"
                      className={classNames('join-sign-up-submit-button text-capitalize px-0 w-100', {
                        small: !isAds10216Chal,
                        'fw-medium size-16': isAds10216Chal,
                      })}
                      data-tracking-id="begin_sign_up"
                      data-no-refresh
                      onClick={handleSubmit}
                      disabled={isSpinnerActive || 'email' in validationErrors}
                    >
                      {isSpinnerActive && <Spinner size={14} thickness={1} color="white" className="align-middle" />}
                      <span
                        className={classNames('fw-normal', {
                          'px-0_5': !isSpinnerActive,
                          'ps-0_5': isSpinnerActive,
                        })}
                      >
                        {isSpinnerActive ? 'Signing' : 'Sign Up'}
                      </span>
                    </Button>
                  </Col>
                </Row>
                {isAds10216Chal && (
                  <div className="form-group h7 mb-0">
                    <ProfileFormErrors
                      fieldsOrder={fieldsOrder}
                      validationErrors={validationErrors}
                      firebaseError={firebaseError}
                    />
                  </div>
                )}
              </Form>
              {isAuthenticated && !isAnonymousUser && isSucceeded && (
                <div className="heading-6 pt-1">
                  <i className="icon-checkmark text-success pe-0_25" aria-hidden />
                  You&#39;re {SIGN[hasAccount]}!
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
}

SignUpComponent.propTypes = {
  validationErrors: PropTypes.objectOf(PropTypes.string),
  validate: PropTypes.func.isRequired,
  fieldsOrder: PropTypes.arrayOf(PropTypes.string),
  firebaseError: PropTypes.shape({}),
  isAuthenticated: PropTypes.bool,
  isAnonymousUser: PropTypes.bool,
  className: PropTypes.string,
  profileScreen: PropTypes.string,
  fieldRef: PropTypes.func.isRequired,
  toggleSignModal: PropTypes.func.isRequired,
  fields: PropTypes.shape({}).isRequired,
  signInAnonymousAndSaveProfile: PropTypes.func.isRequired,
  validateEmailAsync: PropTypes.func.isRequired,
  pageName: PropTypes.string,
  venomVersion: PropTypes.string,
  isAds10216Chal: PropTypes.bool,
};

SignUpComponent.defaultProps = {
  validationErrors: {},
  fieldsOrder: [],
  firebaseError: null,
  isAuthenticated: null,
  isAnonymousUser: false,
  className: '',
  profileScreen: '',
  pageName: '',
  venomVersion: '',
  isAds10216Chal: false,
};

const stateToPropsConfig = {
  pageName: bindToPath('page.name', PageModel),
  venomVersion: bindToPath('version', VenomVersion),
};

const mapStateToProps = state => ({
  isAuthenticated: state.profile.isAuthenticated,
  isAnonymousUser: state.profile.isAnonymousUser,
  profileScreen: state.profile.screen,
});

export const JoinInsider = profileScreenMethods(
  insiderMethods(
    firebaseValidation(
      formValidation(connect(mapStateToProps)(connectToModel(SignUpComponent, stateToPropsConfig)), validators)
    )
  )
);
